import React from 'react'
import './DestinationStyles.css'
import DestinationData from './DestinationData'

const Destination = () => {
  return (
    <div className='destination'>
      <h1>Comfortable Service</h1>
      <p>Empowering Your Loved Ones to Thrive at Home</p>

      <DestinationData
        className='first-des'
        heading='Personalized Care'
        text='We believe in individuality, tailoring our care plans to meet the
        unique needs and preferences of each client. Our experienced caregivers work closely with
        families to develop comprehensive care strategies that ensure your loved ones receive the
        exact support they require.'
        img1='https://plus.unsplash.com/premium_photo-1663036890782-4c00a3bb3832?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1113&q=80'
        img2='https://plus.unsplash.com/premium_photo-1663089104521-fdc5ab858c41?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1172&q=80'/>

<DestinationData
        className='first-des-reverse'
        heading='Independence and Dignity'
        text='Our approach is centered on promoting independence and
        preserving the dignity of our clients. Our caregivers empower your loved ones to maintain
        control over their daily lives, fostering a sense of self-worth and confidence.'
        img1='https://images.unsplash.com/photo-1559234938-b60fff04894d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=987&q=80'
        img2='https://plus.unsplash.com/premium_photo-1664300960074-31fe569f2b56?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80'/>  
    </div>
  )
}

export default Destination