export const MenuItems = [
    // Make a list of Navlinks & their properties.
    {
        title : "Home",
        url : "/",
        cName : "nav-links",
        icon : "fa-solid fa-house"
    },
    {
        title : "About",
        url : "/about",
        cName : "nav-links",
        icon : "fa-solid fa-circle-info"
    },
    {
        title : "Services",
        url : "/services",
        cName : "nav-links",
        icon : "fa-solid fa-briefcase"
    },
    {
        title : "Contact",
        url : "/contact",
        cName : "nav-links",
        icon : "fa-solid fa-address-book"
    },
    /*{
        title : "Sign Up",
        url : "/signup",
        cName : "nav-links-mobile",
    }*/    
  ];