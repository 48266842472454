import './ServiceStyles.css';
import React from 'react'
import ServiceData from './ServiceData';

const Service = () => {
  return (
    <div className='service'>
        <h1>Popular Services</h1>
        <p>You can discover some of our popular services.</p>
        <div className='serviceCard'>
            <ServiceData
                image='https://www.carelinkhomecare.ca/wp-content/themes/carelinkhomeju109/images/mid-box4.jpg'
                heading='Companionship'
                text='Providing companionship and supervision for loved ones'
            />

            <ServiceData
                image='https://plus.unsplash.com/premium_photo-1681995989551-3645d9ce35ad?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80'
                heading='Dementia Care'
                text='With Professionalism and Compassion ensure your loved ones are properly cared for'
            />

            <ServiceData
                image='https://plus.unsplash.com/premium_photo-1682434813431-6e0e49701cc0?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1130&q=80'
                heading='Non-Senior Care'
                text='Providing care for non-seniors and those that might need our services'
            />

            <ServiceData
                image='https://plus.unsplash.com/premium_photo-1661284839497-394d5a44f31f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=987&q=80'
                heading='Personal Care'
                text='Assisting your loved ones with their day to day activites and active living'
            />

            <ServiceData
                image='https://plus.unsplash.com/premium_photo-1665203568927-bf0e58ee3d20?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80'
                heading='Hospice & Palliative Care'
                text='We patiently address the needs of your loved ones respecting their decisions'
            />

            <ServiceData
                image='https://images.unsplash.com/photo-1540778339538-067eae485e9f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=926&q=80'
                heading='Caregiver / Respite Care'
                text='Making time for caregivers to recouperate away from their duties'
            />
        </div>
    </div>
  )
}

export default Service