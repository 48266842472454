import "./ContactFormStyles.css"
import React from 'react'

const ContactForm = () => {
  return (
    <div className="form-container">
        <h1>Send us a message!</h1>
        <h3>admin@jnnproservices.com</h3>
        <h3>(289) 925 7162</h3>
    </div>
  )
}

export default ContactForm