import React from 'react'
import Navbar from '../components/Navbar';
import Hero from '../components/Hero';
import Destination from '../components/Destination';
import Service from '../components/Service';
import Footer from '../components/Footer';

const Home = () => {
  return (
    
    <>
    <Navbar/>
    <Hero
        cName="hero"
        heroImg="https://plus.unsplash.com/premium_photo-1663100292731-1db42aedb494?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80"
        title="Compassionate Care"
        text="Here to serve your care needs"
        btnText="Learn More"
        url="/about"
        btnClass="show"
    />
    <Destination/>
    <Service/>
    <Footer/>
    </>
  )
}

export default Home