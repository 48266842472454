import './FooterStyles.css';
import React from 'react'

const Footer = () => {
  return (
    <div className='footer'>
        <div className='top'>
            <div>
                <h1>JNN</h1>
                <p>Here to serve your care needs</p>
            </div>
            <div>
                <a href='https://www.facebook.com/profile.php?id=61553264154584&mibextid=LQQJ4d'>
                    <i className="fa-brands fa-facebook-square"></i>
                </a>
                <a href='https://www.instagram.com/jnnproservices/'>
                    <i className="fa-brands fa-instagram-square"></i>
                </a>
                <a href='/'>
                    <i className="fa-brands fa-linkedin"></i>
                </a>
                <a href='/'>
                    <i className="fa-brands fa-twitter-square"></i>
                </a>
            </div>
        </div>
        <div className='bottom'>
            <a href='/'>Home</a>
            <a href='/'>About</a>
            <a href='/'>Services</a>
            <a href='/'>Contact</a>
        </div>
    </div>
  )
}

export default Footer