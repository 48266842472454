import "./AboutUsStyles.css";
import React from 'react'

const AboutUs = () => {
  return (
    <div className="about-container">
        <h1>Our Mission</h1>
        <p>Our mission is to deliver holistic, family-centered care that profoundly
enriches the lives we touch, creating lasting positive impacts.</p>

        <h1>Our History</h1>
        <p>JNN Professional Services: Bridging Healthcare Gaps and Fostering Holistic Care<br/>

JNN Professional Services emerged as a response to the pressing healthcare needs exposed by
the COVID-19 pandemic. Founded by Jayne, an individuals with a deep-rooted
commitment to family, love, and support, this healthcare company was established with the
primary aim of addressing the gaps in homecare and to provide respite care for clients and their
families.<br/>
The COVID-19 pandemic illuminated the vulnerabilities of elderly individuals, people with
disabilities, and those requiring homecare support. It underscored the challenges faced by
families in managing their responsibilities while taking care of their loved ones. Recognizing this

pressing need, Jayne embarked on a mission to make a difference in the lives of these
individuals and their families.<br/>
One of the core strengths of JNN Professional Services is the unique blend of healthcare
expertise and business acumen brought to the table by its founder. With over a decade of nursing experience, 
and an MBA degree, Jayne used her healthcare and business experience, to lay the foundation for a company that aspired to
provide holistic care to its clients and alleviate the strain on the healthcare system, particularly
by reducing rehospitalization rates.
        </p>

        <h1>Our Vision</h1>
        <p>We envision a future where our holistic, family-centered care transforms the
lives of those we serve, creating a ripple effect of positivity throughout communities.</p>

        <h1>Core Values</h1>
        <p><b>Respect:</b> We hold respect as the cornerstone of our interactions, treating every individual with
dignity and courtesy.<br/>
<b>Compassion:</b> Our work is driven by a deep sense of compassion, providing comfort, and
understanding in times of need.<br/>
<b>Ethical Practices:</b> We maintain the highest ethical standards in all our actions, ensuring
transparency and trust.<br/>
<b>Empathy:</b> We actively listen and understand the emotions and experiences of others, fostering
a genuine connection and support.<br/>
<b>Privacy and Confidentiality:</b> We uphold the utmost commitment to safeguarding the privacy
and confidentiality of all individuals under our care, respecting their personal information and
dignity.</p>
    </div>
  )
}

export default AboutUs